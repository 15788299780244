import type { WP_Options } from '@/types/wordpress'
import { IconChevronRight } from '@tabler/icons-react'
import { useLocale } from 'next-intl'

import { getName } from 'i18n-iso-countries'
import { cn } from '@/lib/utils/cn'
import Image from 'next/image'
import { Region } from '@/lib/types'

const Country = ({ country_code, image, title, num_countries, active, setActive, options }: Props) => {
	const locale = useLocale()

	const isCountry = typeof num_countries !== 'number'

	const name = getName(country_code, locale) || title

	return (
		<div
			className={cn(
				'flex justify-between items-center rounded-3xl p-4 duration-200 cursor-pointer hover:bg-[#e0e0e0]',
				active ? 'bg-gradient' : 'bg-[#F9F9F9]'
			)}
			onClick={setActive}
		>
			<div className='gap-4 flex'>
				<div
					className={cn(
						'rounded-full overflow-hidden flex items-center justify-center w-[52px] h-[52px]',
						isCountry ? 'bg-white' : 'bg-[#f5f7f9]'
					)}
				>
					<Image
						src={image}
						alt={title}
						className='object-contain w-8 h-8'
						height={32}
						width={32}
					/>
				</div>

				<div className='flex flex-col justify-center'>
					<h4 className={cn('font-bold text-lg', active ? 'text-white' : 'text-grayish')}>{name}</h4>
					{!isCountry && (
						<p className={cn('font-semibold text-opacity-40', active ? 'text-white' : 'text-grayish')}>
							{num_countries} {options[`${locale}_homepage_plans_countries`]}
						</p>
					)}
				</div>
			</div>
			<IconChevronRight className={active ? 'rtl:-scale-x-100 text-white' : 'rtl:-scale-x-100 text-blue'} />
		</div>
	)
}

interface Props extends Region {
	active: boolean
	setActive: () => void
	options: WP_Options
}

export default Country
