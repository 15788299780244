'use client';

import type { WP_Options } from '@/types/wordpress';
import { IconArrowRight, IconInfoCircleFilled, IconSearch } from '@tabler/icons-react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { getName, registerLocale } from 'i18n-iso-countries';
import { auth, firestore } from '@/providers/FirebaseApp';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { doc } from 'firebase/firestore';
import { LOCALES_KEYS } from '@/i18n';
import { useLocale } from 'next-intl';

import Country from './Country';
import { type Region, type Country as CountryType } from '@/lib/types';
import DataPlan, { DataPlanSkeleton, type Plan } from './DataPlan';
import Input from '@/app/_components/Reusable/Input';
import CountryTag, { CountryTagSkeleton } from './CountryTag';
import Image from 'next/image';
import Link from 'next/link';
import Modal from './Modal';

LOCALES_KEYS.forEach((locale) => registerLocale(require(`i18n-iso-countries/langs/${locale}.json`)));

const MAX_COUNTRIES = 7;

interface Operator {
	packages: Plan[];
	countries: AvailableCountry[];
}
const sortPlansByDataSize = (plans: Plan[]): Plan[] => {
	return plans.sort((a, b) => a.amount - b.amount); // Assuming `amount` represents the data size (e.g., 1024 for 1GB, 2048 for 2GB, etc.)
};
const findOperatorWithCheapestPlan = (operators: Operator[]): Operator | null => {
	let cheapestOperator: Operator | null = null;
	let lowestPrice = Infinity;

	operators.forEach((operator) => {
		operator.packages.forEach((plan) => {
			if (plan.price < lowestPrice) {
				lowestPrice = plan.price;
				cheapestOperator = operator;
			}
		});
	});

	return cheapestOperator;
};

const PlansSection = ({
	dbCountries: countries,
	dbRegions: regions,
	options,
}: {
	dbCountries: CountryType[];
	dbRegions: Region[];
	options: WP_Options;
}) => {
	const locale = useLocale();

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [barcodeSrc, setBarcodeSrc] = useState<string>('');
	const [downloadTitle, setDownloadTitle] = useState<string>('');

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user?.isAnonymous) {
				setIsAuthenticated(true);
			} else {
				setIsAuthenticated(false);
			}
		});

		// Clean up the subscription on unmount
		return () => unsubscribe();
	}, []);

	const [search, setSearch] = useState('');
	const [active, setActive] = useState<string>(regions?.[0]?.slug || countries?.[0]?.country_code || '');

	const isActiveRegion = regions.find((region) => region.slug === active) !== undefined;

	const [packages, packagesIsLoading, packagesError] = useDocumentData(
		active && isAuthenticated ? doc(firestore, isActiveRegion ? 'packages_region' : 'packages_local', active) : null
	);

	const activeCountryOrRegion = isActiveRegion
		? regions?.find((region) => region.slug === active)
		: countries?.find((country) => country.country_code === active);

	let plans: Plan[] = packages?.operators?.flatMap((operator: Operator) => operator.packages) || [];
	// console.log('plans', plans);
	// Sort plans by price (cheapest first)
	// plans = plans.sort((a, b) => a.price - b.price);

	// console.log('plans', plans);

	// const availableCountries: AvailableCountry[] = Array.from(new Set(packages?.operators?.flatMap((operator: Operator) => operator.countries.filter((country) => country.image.url.includes('airalo')).map((country) => country.country_code)))) || [];
	const uniqueCountries = packages?.operators?.flatMap((operator: Operator) => operator.countries)
	.filter((country: any) => country.image?.url.includes('airalo'))  // Step 1: Filter countries with "airalo" in the image URL
	.reduce((acc: any, country: any) => {
		acc.set(country.country_code, country);  // Step 2: Use a Map to ensure unique country_code
		return acc;
	}, new Map())
	.values();  // Extract the values from the Map

	const availableCountries: AvailableCountry[] = uniqueCountries ? Array.from(uniqueCountries) : [];
	// console.log('availableCountries', availableCountries);
	const availableCountriesCount = availableCountries.length;
	const shownCountries = availableCountries.slice(0, MAX_COUNTRIES);
	const moreCountries = availableCountriesCount - MAX_COUNTRIES;

	const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

	// console.log('The final cheapest plan is', cheapestPlan);

	useEffect(() => {
		if (plans && (!selectedPlan || !plans.find((plan) => plan.id === selectedPlan.id))) {
			setSelectedPlan(plans[0]);
		}
	}, [plans, selectedPlan]);

	const handlePlanClick = () => {
		setBarcodeSrc(options[`${locale}_barcode_download`]);
		setDownloadTitle(options[`${locale}_download_title`]);
		setModalOpen(true);
	};

	const filteredRegions = regions?.filter((region) => region.title.toLowerCase().includes(search.toLowerCase())) || [];
	const filteredCountries =
		countries?.filter((country) => country.title.toLowerCase().includes(search.toLowerCase())) || [];

	// useEffect(() => {
	// 	if (packages) {
	// 		// Find the operator with the cheapest plan
	// 		const cheapestOperator = findOperatorWithCheapestPlan(packages.operators);

	// 		// Mark all plans of this operator as "Best Price"
	// 		if (cheapestOperator) {
	// 			cheapestOperator.packages.forEach((plan) => {
	// 				plan.isBestPrice = true;
	// 			});
	// 		}

	// 		// Sort operators, placing the one with the cheapest plan first
	// 		const sortedOperators = packages.operators.sort((a: any, b: any) => {
	// 			if (a === cheapestOperator) return -1;
	// 			if (b === cheapestOperator) return 1;
	// 			return 0;
	// 		});

	// 		// Flatten the plans in the order of sorted operators
	// 		plans = sortedOperators.flatMap((operator: Operator) => operator.packages);

	// 		// Set the selected plan to the first in the sorted list
	// 		setSelectedPlan(plans[0]);
	// 	}
	// }, [packages]);
	useEffect(() => {
        if (packages) {
            // Find the operator with the cheapest plan
            const cheapestOperator = findOperatorWithCheapestPlan(packages.operators);

            // Mark all plans of the cheapest operator as "Best Price"
            if (cheapestOperator) {
                cheapestOperator.packages.forEach((plan) => {
                    plan.isBestPrice = true;
                });
            }

            // Sort each operator's plans by data size
            const sortedOperators = packages.operators.map((operator: Operator) => {
                operator.packages = sortPlansByDataSize(operator.packages);
                return operator;
            });

            // Prioritize the cheapest operator first, then the rest by their cheapest plan
            // const sortedAndPrioritizedOperators = sortedOperators.sort((a: Operator, b: Operator) => {
            //     const cheapestA = a.packages[0].price; // Cheapest plan for operator A
            //     const cheapestB = b.packages[0].price; // Cheapest plan for operator B
            //     return cheapestA - cheapestB; // Sort operators by their cheapest plan
            // });
			const sortedAndPrioritizedOperators = packages.operators.sort((a: Operator, b: Operator) => {
							if (a === cheapestOperator) return -1;
							if (b === cheapestOperator) return 1;
							return 0;
						});

            // Flatten the plans, with the cheapest operator’s plans appearing first
            const allPlansInOrder: Plan[] = sortedAndPrioritizedOperators.flatMap((operator: Operator) => operator.packages);

            // Set the selected plan to the first in the sorted list
            setSelectedPlan(allPlansInOrder[0]);
        }
    }, [packages]);
	return (
		<div className="container space-y-5 mt-16 lg:mt-20" id="plans">
			<h2 className="text-center lg:text-start w-full font-bold text-2xl lg:text-3xl text-grayish whitespace-pre">
				{options[`${locale}_homepage_plans_title`].replace('\\n', '\n')}
			</h2>
			<div className="flex flex-col lg:grid lg:grid-cols-5 gap-6">
				<div className="col-span-2 bg-white rounded-3xl overflow-hidden relative fade-out">
					<div className="p-6">
						<Input
							icon={IconSearch}
							value={search}
							onValueChange={setSearch}
							placeholder={options[`${locale}_homepage_plans_searchPlaceholder`]}
							id="plans-search"
						/>
					</div>
					<div className="p-6 pt-0 h-full overflow-y-scroll flex flex-col gap-4 max-h-[530px]">
						{filteredRegions.length > 0 ? (
							<>
								<h3 className="text-grayish text-opacity-30 font-bold text-lg">
									{options[`${locale}_homepage_plans_regional`]}
								</h3>
								<div className="space-y-2">
									{filteredRegions.map((region) => (
										<Country
											key={region.slug}
											active={active === region.slug}
											setActive={() => setActive(region.slug)}
											options={options}
											{...region}
										/>
									))}
								</div>
							</>
						) : null}
						{filteredCountries.length > 0 ? (
							<>
								<h3 className="text-grayish text-opacity-30 font-bold text-lg">
									{options[`${locale}_homepage_plans_countries`]}
								</h3>
								<div className="space-y-2">
									{filteredCountries.map((country) => (
										<Country
											key={country.country_code}
											active={active === country.country_code}
											setActive={() => setActive(country.country_code)}
											options={options}
											{...country}
										/>
									))}
								</div>
							</>
						) : null}
					</div>
				</div>
				<div className="col-span-3 bg-white rounded-3xl flex flex-col gap-6 p-6">
					{activeCountryOrRegion && (
						<>
							<div className="flex justify-between items-start gap-6">
								<div>
									<h3 className="text-2xl font-bold text-grayish">{activeCountryOrRegion.title} eSIM</h3>
									<h4 className="text-xl font-semibold text-grayish text-opacity-40">
										{options[`${locale}_homepage_plans_subtitle`]}
									</h4>
								</div>
								<Image
									src={activeCountryOrRegion.image}
									className="w-[90px] h-14 country-flag"
									alt="logo"
									width={80}
									height={54}
								/>
							</div>
							<div className="space-y-3">
								<h5 className="font-semibold text-grayish text-opacity-40">
									{options[`${locale}_homepage_plans_choose`]}
								</h5>
								<div className="flex gap-2 overflow-x-auto">
									{plans
										? plans.map((plan) => (
											// <DataPlan
											// 	key={plan.id}
											// 	active={selectedPlan?.id === plan.id}
											// 	onClick={() => setSelectedPlan(plan)}
											// 	options={options}
											// 	isBestPrice={cheapestPlan?.id === plan.id}
											// 	isVoiceAndData={!!plan.voice && plan.voice > 0 && plan.id !== cheapestPlan?.id}
											// 	{...plan}
											// />
											<DataPlan
													key={plan.id}
													active={selectedPlan?.id === plan.id}
													onClick={() => setSelectedPlan(plan)}
													options={options}
													isBestPrice={plan.isBestPrice}
													isVoiceAndData={!!plan.voice && plan.voice > 0 && plan.id !== selectedPlan?.id}
													{...plan}
												/>
										))
										: Array.from({ length: 6 }).map((_, i) => <DataPlanSkeleton key={i} />)}
								</div>
							</div>
							<div className="w-full rounded-[30px] font-semibold text-grayish flex flex-col gap-3 lg:gap-6 p-6 bg-[#0A71FA0D]">
								<div className="flex justify-between">
									<div className="flex flex-col items-center mx-auto lg:items-start lg:mx-0">
										<span className="text-grayish text-opacity-30">
											{options[`${locale}_homepage_plans_price`]}
										</span>
										<h3 className="font-bold text-4xl">${selectedPlan?.price}</h3>
									</div>
									<div className="hidden lg:flex gap-3 text-sm items-start">
										<Link href="#" className="flex items-center text-grayish text-opacity-30 gap-1">
											<IconInfoCircleFilled size={13.3} />
											{options[`${locale}_homepage_plans_details`]}
										</Link>
										<Link href="#" className="flex items-center text-grayish text-opacity-30 gap-1">
											<IconInfoCircleFilled size={13.3} />
											{options[`${locale}_homepage_plans_about`]}
										</Link>
									</div>
								</div>
								<button
									className="bg-gradient shadow-gradient rounded-full py-6 text-white font-bold text-xl flex gap-2 items-center justify-center"
									onClick={handlePlanClick}
								>
									{options[`${locale}_homepage_plans_buy_for`]} ${selectedPlan?.price}{' '}
									<IconArrowRight size={24} className="rtl:-scale-x-100" />
								</button>
								<div className="lg:hidden flex gap-3 text-sm items-start mx-auto">
									<Link href="#" className="flex items-center text-grayish text-opacity-30 gap-1">
										<IconInfoCircleFilled size={13.3} />
										{options[`${locale}_homepage_plans_details`]}
									</Link>
									<Link href="#" className="flex items-center text-grayish text-opacity-30 gap-1">
										<IconInfoCircleFilled size={13.3} />
										{options[`${locale}_homepage_plans_about`]}
									</Link>
								</div>
							</div>
							<div className="space-y-3 min-h-[85px]">
								<span className="font-semibold text-grayish text-opacity-40">
									{options[`${locale}_homepage_plans_also_available`]}
								</span>
								<div className="flex gap-2 overflow-x-auto">
									{shownCountries.length > 0
										? shownCountries.map((country) => (
											<CountryTag
												onClick={() => setActive(country.country_code)}
												key={country.country_code}
											>
												{getName(country.country_code, locale) || country.title}
											</CountryTag>
										))
										: Array.from({ length: MAX_COUNTRIES }).map((_, i) => (
											<CountryTagSkeleton key={i} />
										))}
									{moreCountries > 0 ? (
										<CountryTag primary>
											+
											{options[`${locale}_homepage_plans_more`].replace(
												'{count}',
												moreCountries.toString()
											)}
										</CountryTag>
									) : (
										<></>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			<Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} barcodeSrc={barcodeSrc} downloadTitle={downloadTitle} />
		</div>
	);
};

interface AvailableCountry {
	country_code: string;
	title: string;
	image: {
		width: number;
		height: number;
		url: string;
	};
}

export default PlansSection;
