// // Modal.tsx
// import React from 'react';
// import Image from 'next/image';
// import Link from 'next/link';
// import { GOOGLE_APPLE_STORE_URL, GOOGLE_PLAY_STORE_URL } from '@/lib/constants/endpoints';

// interface ModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   barcodeSrc: string;
//   downloadTitle: string; // Add downloadTitle prop
// }

// const Modal: React.FC<ModalProps> = ({ isOpen, onClose, barcodeSrc, downloadTitle }) => {
//   if (!isOpen) return null;

//   const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
//     if (e.target === e.currentTarget) {
//       onClose();
//     }
//   };

//   return (
//     <div
//       className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
//       onClick={handleClose}
//     >
//       <div className="bg-white p-6 rounded-lg relative w-11/12 max-w-md mx-auto flex flex-col items-center">
//         <button
//           className="absolute top-2 right-2 text-black"
//           onClick={onClose}
//         >
//           &times;
//         </button>
//         <h5 className="text-xl font-bold text-center mb-4">{downloadTitle}</h5>
//         <div className="hidden lg:block">
//           <Image src={barcodeSrc} alt="Barcode" width={200} height={200} priority className="m-auto" />
//         </div>
//         <div className="mt-4 flex flex-col lg:flex-row items-center justify-center gap-2 w-full">
//           <Link
//             href={GOOGLE_APPLE_STORE_URL}
//             className="bg-black py-2 px-4 rounded-xl min-h-[50px] w-full lg:w-auto text-center"
//           >
//             <Image src="/download/appstore.svg" alt="app-store" className="h-9 w-auto mx-auto" width={169} height={38} priority />
//           </Link>
//           <Link
//             href={GOOGLE_PLAY_STORE_URL}
//             className="bg-black py-2 px-4 rounded-xl min-h-[50px] w-full lg:w-auto text-center"
//           >
//             <Image src="/download/playstore.svg" alt="play-store" className="h-9 w-auto mx-auto" width={169} height={38} priority />
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Modal;
import React from 'react';
import ReactDOM from 'react-dom';
import Image from 'next/image';
import Link from 'next/link';
import { GOOGLE_APPLE_STORE_URL, GOOGLE_PLAY_STORE_URL } from '@/lib/constants/endpoints';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  barcodeSrc: string;
  downloadTitle: string; // Add downloadTitle prop
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, barcodeSrc, downloadTitle }) => {
  if (!isOpen) return null;

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const modalContent = (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleClose}
    >
      <div className="bg-white p-6 rounded-lg relative w-11/12 max-w-md mx-auto flex flex-col items-center">
        <button
          className="absolute top-4 right-4 text-black"
          onClick={onClose}
        >
          <svg viewBox="0 -0.5 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000" className="w-3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Dribbble-Light-Preview" transform="translate(-419.000000, -240.000000)" fill="#000000"> <g id="icons" transform="translate(56.000000, 160.000000)"> <polygon id="close-[#1511]" points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"> </polygon> </g> </g> </g> </g></svg>
        </button>
        <h5 className="text-xl font-bold text-center mb-4">{downloadTitle}</h5>
        <div className="hidden lg:block">
          <Image src={barcodeSrc} alt="Barcode" width={200} height={200} priority className="m-auto" />
        </div>
        <div className="mt-4 flex flex-col lg:flex-row items-center justify-center gap-2 w-full">
          <Link
            href={GOOGLE_APPLE_STORE_URL}
            className="bg-black py-2 px-4 rounded-xl min-h-[50px] w-full lg:w-auto text-center"
          >
            <Image src="/download/appstore.svg" alt="app-store" className="h-9 w-auto mx-auto" width={169} height={38} priority />
          </Link>
          <Link
            href={GOOGLE_PLAY_STORE_URL}
            className="bg-black py-2 px-4 rounded-xl min-h-[50px] w-full lg:w-auto text-center"
          >
            <Image src="/download/playstore.svg" alt="play-store" className="h-9 w-auto mx-auto" width={169} height={38} priority />
          </Link>
        </div>
      </div>
    </div>
  );

  // Use createPortal to teleport the modal content to the end of the body
  return ReactDOM.createPortal(modalContent, document.body);
};

export default Modal;
