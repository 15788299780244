import type { Icon, IconProps } from '@tabler/icons-react'
import useTextDirection from '@/lib/hooks/useTextDirection'
import React from 'react'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	icon?: React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<Icon>>
	onValueChange?: (value: string) => void
}

const Input: React.FC<InputProps> = ({ icon, onValueChange, ...props }) => {
	const dir = useTextDirection()

	const Icon = icon

	return (
		<div className={`flex items-center w-full border border-[#2420201A] rounded-full relative overflow-hidden`}>
			{Icon && (
				<Icon
					size={18}
					className={`text-[fill-grayish] opacity-30 absolute ${dir === 'rtl' ? 'right-5' : 'left-5'}`}
				/>
			)}
			<input
				className='flex-grow placeholder-grayish placeholder-opacity-30 font-semibold py-5 px-12 outline-none'
				onChange={(e) => onValueChange?.(e.target.value)}
				{...props}
			/>
		</div>
	)
}

export default Input
