import React from 'react';

interface TagProps {
  text: string;
  backgroundColor: string;
  textColor: string;
  opacity?: number; // Optional opacity prop
}

const Tag: React.FC<TagProps> = ({ text, backgroundColor, textColor, opacity = 1 }) => {
  return (
    <div
      className="inline-flex items-center rounded-full justify-center py-1 px-2 w-min"
      style={{
        backgroundColor: backgroundColor,
        opacity: opacity,
      }}
    >
      <span
        className="text-xs font-bold min-w-max"
        style={{
          color: textColor,
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default Tag;
