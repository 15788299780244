const CountryTag = ({
	primary,
	children,
	onClick,
}: {
	primary?: boolean
	children: JSX.Element | string | string[]
	onClick?: () => void
}) => {
	return (
		<div
			className={`rounded-2xl p-3 text-sm font-semibold whitespace-nowrap ${
				primary ? 'text-blue bg-gradient bg-opacity-5' : 'bg-[#F9F9F9] text-[#242020] text-opacity-30'
			} ${onClick ? 'cursor-pointer hover:bg-[#ececec] duration-200' : ''}`}
			onClick={onClick}
		>
			{children}
		</div>
	)
}

export const CountryTagSkeleton = () => {
	return (
		<div className='rounded-2xl p-3 text-sm font-semibold whitespace-nowrap bg-[#F9F9F9] text-[#242020] text-opacity-30 animate-pulse w-24 h-11'></div>
	)
}

export default CountryTag
